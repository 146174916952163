import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import { Route, Router, Routes, provideRouter } from '@angular/router';

import { provideAnimations } from '@angular/platform-browser/animations';
import { provideServiceWorker } from '@angular/service-worker';
import { CurrencyPipe, DatePipe, DecimalPipe, TitleCasePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  ErrorReporterService,
  GlobalErrorHandlerService,
  InputFormattersPipe,
  LoggerInterceptorService,
  pageErrorRouter as PER,
} from 'ets-fe-ng-sdk';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { AuthEffect } from './@ngrx/auth/auth.effect';
import { metaReducers, reducers } from './@ngrx/index.reducer';
import { CompanyEffect } from './@ngrx/company/company.effect';
import { ConfigurationEffect } from './@ngrx/configuration/configuration.effect';
import { UserEffect } from './@ngrx/user/user.effect';
import { UserGroupEffect } from './@ngrx/user-group/user-group.effect';
import { AgentTypeEffect } from './@ngrx/agent-type/agent-type.effect';
import { CodeEffect } from './@ngrx/code/code.effect';
import { MenuEffect } from './@ngrx/menu/menu.effect';
import { AuthenticationInterceptorService } from './services/Interceptors/authentication-interceptor.service';
import { checkAccessSetup } from './models/access.model';
// import { environment } from '../environments/environment';
import { CourseEffect } from './@ngrx/course/course.effect';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { authGuard } from './auth.guard';
import { MAT_TABS_CONFIG, MatTabsConfig } from '@angular/material/tabs';
import * as Sentry from '@sentry/angular';
import { QuillModule } from 'ngx-quill';
import { Environment } from './models/environment.model';
// import { authReducer } from './@ngrx/reducers/auth.reducer';

checkAccessSetup();
const pageErrorRouter = (route: Route, dataConfig: Parameters<typeof PER>[0]['dataConfig']) =>
  PER({ dataConfig, route });

export function sharedAppConfig(routes: Routes, environmentParams: Environment): ApplicationConfig {
  // if (environmentParams.production)
     Sentry.init(ErrorReporterService.sentryOptions(environmentParams, []));
  const extraDashRoutes: Routes = [
    {
      path: 'profile',
      data: { title: 'Profile' },
      loadComponent: () => import('./components/profile/profile.component').then((c) => c.ProfileComponent),
    },
    {
      path: 'company',
      loadChildren: () =>
        import('../../../admin/src/app/dashboard/set-ups/companies/companies.routes').then((r) =>
          r.companyRoutes(true),
        ),
    },
    ...(environmentParams.isCandidate
      ? []
      : <Routes>[
          {
            path: 'help',
            loadChildren: () => import('./components/help/help.routes'),
          },
        ]),
  ];
  const extraNoDashRoutes: Routes = [
    {
      path: '',
      canActivateChild: [authGuard],
      children: [
        {
          path: 'help/view',
          data: { title: `Help` },
          loadComponent: () =>
            import('./components/help/view-help/view-help.component').then((r) => r.ViewHelpComponent),
        },
        {
          path: 'courses/view',
          data: { title: `View Course` },
          loadComponent: () =>
            import('./components/courses/view-course/view-course.component').then(
              (r) => r.ViewCourseComponent,
            ),
        },
        {
          path: '',
          loadChildren: () =>
            import('../../../shared/src/lib/components/view-certificate/view-certificate.route').then(
              (r) => r.viewCertRoutes,
            ),
        },
      ],
    },
  ];
  routes[0].children?.push(...extraDashRoutes);
  routes.push(...extraNoDashRoutes);
  return {
    providers: [
      importProvidersFrom(QuillModule.forRoot()),
      provideRouter([
        {
          path: '',
          children: routes,
          canActivateChild: [authGuard],
        },
        {
          path: 'auth',
          loadChildren: () => import('./authentication/authentication.routes'),
        },
        {
          path: 'maintenance-mode',
          loadComponent: () =>
            import('./components/maintenance-mode/maintenance-mode.component').then(
              (c) => c.MaintenanceModeComponent,
            ),
        },
        pageErrorRouter(
          { path: 'wpce' },

          {
            hideButtons: true,
            status: 401,
            message: `You do not have access to the candidate platform.`,
          },
        ),
        pageErrorRouter(
          { path: 'wpp' },
          {
            hideButtons: true,
            status: 401,
            btn: {
              label: `Partner Portal`,
              type: 'secondary',
              action: () => {
                localStorage.clear();
                sessionStorage.clear();
                location.href = environmentParams.partnerURL;
              },
            },
            message: `You are on the wrong platform.<br>Please visit the Partner portal.`,
          },
        ),
        pageErrorRouter(
          { path: 'wpa' },

          {
            hideButtons: true,
            status: 401,
            btn: {
              label: `Admin Portal`,
              type: 'secondary',
              action: () => {
                localStorage.clear();
                sessionStorage.clear();
                location.href = environmentParams.adminURL;
              },
            },
            message: `You are on the wrong platform.<br>Please visit the Admin portal.`,
          },
        ),
        {
          path: '',
          // canActivateChild: [AuthenticationGuard],
          loadChildren: () => import('ets-fe-ng-sdk').then((m) => m.ETSExtraPagesModule),
        },
      ]),
      provideAnimations(),
      provideServiceWorker('ngsw-worker.js', {
        enabled: !isDevMode(),
        registrationStrategy: 'registerWhenStable:30000',
      }),
      provideHttpClient(withInterceptorsFromDi()),
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthenticationInterceptorService,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: LoggerInterceptorService,
        multi: true,
      },
      InputFormattersPipe,
      DecimalPipe,
      CurrencyPipe,
      DatePipe,
      TitleCasePipe,
      provideStore(reducers, { metaReducers }),
      provideEffects(
        // CodeGroupEffect,
        AgentTypeEffect,
        AuthEffect,
        CodeEffect,
        CompanyEffect,
        ConfigurationEffect,
        MenuEffect,
        CourseEffect,
        UserEffect,
        UserGroupEffect,
      ),
      provideCharts(withDefaultRegisterables()),
      { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
      ...ErrorReporterService.sentryProviderOptions(),
      { provide: MAT_TABS_CONFIG, useValue: <MatTabsConfig>{ dynamicHeight: true } },
    ],
  };
}
