import { Injectable, inject } from '@angular/core';
import { map, mergeMap, of } from 'rxjs';
import { createEffect, ofType } from '@ngrx/effects';
import { IMenuItem } from '../../models/menu.model';
import { BaseEffect } from '../base.effect';
import { MenuStore } from './menu.reducer';
import { AppService } from '../../services/app.service';

@Injectable()
export class MenuEffect extends BaseEffect {
  protected appS=inject(AppService)

  setMenu = createEffect(() => {
    return this.actions$.pipe(
      ofType(MenuStore.action.setMenu),
      mergeMap((action) =>
        of(this.appS.formatMenu(action.result)).pipe(map((result) => MenuStore.action.setMenuSuccess({ result }))),
      ),
    );
  });
}
