import { IMenuItem } from '../../models/menu.model';
import { setupMenu } from './setup.menu';

export const superAdminMenu: IMenuItem[] = [
  setupMenu,
  {
    label: 'Account Reconciliations',
    path: '/super/account-recon',
    icon: 'fa fa-book',
  },
  {
    label: 'Candidates',
    path: '/super/candidates',
    icon: 'fa fa-users',
  },
];
