import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RemitaService {
  protected scriptID = 'remitaScript';
  constructor() {
    this.loadScript();
  }

  loadScript() {
    if (!environment.useRemita) return;
    if (document.querySelector(`script#${this.scriptID}`)) return;
    const script = document.createElement('script');
    script.id = this.scriptID;
    script.src = `${environment.remitaBaseURL}payment/v1/remita-pay-inline.bundle.js`;
    document.getElementsByTagName('body')[0].appendChild(script);
  }
}
