import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { SharedAppComponent } from '../../../shared/src/lib/shared-app.component';
import { ToastNotificationsComponent } from '@serene-dev/toast-notifications';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet,ToastNotificationsComponent], 
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends SharedAppComponent {
  constructor() {
    super();
    // this.menuF.setMenu(this.appService.adminMenu());
  }
  override ngAfterViewInit() {
    super.ngAfterViewInit();
  }
  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
