import { uniq, uniqBy } from 'lodash';
import { environment } from '../../environments/environment';

export enum EAccess {
  intermediaryDesk = 'allowIntermediaryDesk',
  intermediaryReport = 'allowIntermediaryReport',
  policyClaimData = 'allowPolicyClaimData',
  policyDesk = 'allowPolicyDesk',
  policyProdData = 'allowPolicyProdData',
  readBrokerApplication = 'allowReadBrokerApplication',
  readCompany = 'allowReadCompany',
  readConfig = 'allowReadConfig',
  readMaintainTiedAgent = 'allowReadMaintainTiedAgent',
  readPolicyFraud = 'allowReadPolicyFraud',
  readRegisterBrokerAgent = 'allowReadRegisterBrokerAgent',
  readRegisterReferralAgent = 'allowReadRegisterReferralAgent',
  readRegisterTiedAgent = 'allowReadRegisterTiedAgent',
  readUser = 'allowReadUser',
  readUserGroup = 'allowReadUserGroup',
  setupDesk = 'allowSetupDesk',
  workflowDesk = 'allowWorkflowDesk',
  writeBrokerApplication = 'allowWriteBrokerApplication',
  writeCompany = 'allowWriteCompany',
  writeConfig = 'allowWriteConfig',
  writeMaintainTiedAgent = 'allowWriteMaintainTiedAgent',
  writePolicyFraud = 'allowWritePolicyFraud',
  writeRegisterBrokerAgent = 'allowWriteRegisterBrokerAgent',
  writeRegisterReferralAgent = 'allowWriteRegisterReferralAgent',
  writeRegisterTiedAgent = 'allowWriteRegisterTiedAgent',
  writeUser = 'allowWriteUser',
  writeUserGroup = 'allowWriteUserGroup',
}

export enum EAccessGroup {
  intermediaryDesk = 'Intermediary Desk',
  policyDesk = 'Policy Desk',
  setupDesk = 'Setup Desk',
  workflowDesk = 'Workflow Desk',
}
export const AccessConfig: { [k in EAccessGroup]: EAccess[] } = {
  [EAccessGroup.intermediaryDesk]: [
    EAccess.intermediaryDesk,
    EAccess.intermediaryReport,
    EAccess.writeBrokerApplication,
    EAccess.writeMaintainTiedAgent,
    EAccess.writeRegisterBrokerAgent,
    EAccess.writeRegisterReferralAgent,
    EAccess.writeRegisterTiedAgent,
    EAccess.readBrokerApplication,
    EAccess.readMaintainTiedAgent,
    EAccess.readRegisterBrokerAgent,
    EAccess.readRegisterReferralAgent,
    EAccess.readRegisterTiedAgent,
  ],
  [EAccessGroup.policyDesk]: [
    EAccess.policyClaimData,
    EAccess.policyDesk,
    EAccess.policyProdData,
    EAccess.writePolicyFraud,
    EAccess.readPolicyFraud,
  ],
  [EAccessGroup.setupDesk]: [
    EAccess.writeUser,
    EAccess.writeUserGroup,
    EAccess.readUser,
    EAccess.readUserGroup,
    EAccess.writeCompany,
    EAccess.writeConfig,
    EAccess.readCompany,
    EAccess.readConfig,
    EAccess.setupDesk,
  ],
  [EAccessGroup.workflowDesk]: [EAccess.workflowDesk],
};

export function checkAccessSetup() {
  if (environment.production) return;
  const enumValues = Object.values(EAccess),
    accessConfigFlat = uniq(Object.entries(AccessConfig).flatMap(([k, v]) => v));
  if (enumValues.length != accessConfigFlat.length) {
    const msges = [`Access enums ${enumValues.length} are not in the config ${accessConfigFlat.length}\n`];
    const accessConfigFlatMap: { [x in EAccess]?: true } = {};
    accessConfigFlat.forEach((x) => (accessConfigFlatMap[x] = true));
    // debugger
    enumValues.forEach((x) => {
      if (!accessConfigFlatMap[x]) msges.push(`\n${msges.length}. ${x} is missing in the config`);
    });
    throw msges.join('');
  }
}
