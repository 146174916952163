import { Injectable, inject } from '@angular/core';
import { catchError, distinct, exhaustMap, filter, first, map, mergeMap, of, tap } from 'rxjs';
import { createEffect, ofType } from '@ngrx/effects';
import { CodeStore } from './code.reducer';
import { BaseEffect } from '../base.effect';
import { CodeService } from '../../../../../admin/src/app/dashboard/set-ups/parameters/code.service';

@Injectable()
export class CodeEffect extends BaseEffect {
  protected service = inject(CodeService);

  searchCodes = createEffect(() =>
    this.actions$.pipe(
      ofType(CodeStore.actions.searchCodes),
      this.uS.ngRXChecksumGenerator('query'),
      // map(this.uS.ngRXChecksumGenerator('query')),
      distinct((action) => action.checksum),
      mergeMap(({ action }) => {
        // debugger;
        return this.service.search(action.query).pipe(
          map((r) =>
            CodeStore.actions.searchCodesSuccess({
              result: r.content.map((x) => ({ ...x, active: 1 })),
            }),
          ),
          catchError((error) => of(CodeStore.actions.searchCodesError({ error }))),
        );
      }),
    ),
  );

  fetchAll = createEffect(() =>
    this.actions$.pipe(
      ofType(CodeStore.actions.fetchAll),
      exhaustMap((action) =>
        this.service.search({}).pipe(
          map((r) =>
            CodeStore.actions.searchCodesSuccess({
              result: r.content.map((x) => ({ ...x, active: 1 })),
            }),
          ),
          catchError((error) => of(CodeStore.actions.searchCodesError({ error }))),
        ),
      ),
    ),
  );

  fetchAllSubgroups = createEffect(() =>
    this.actions$.pipe(
      ofType(CodeStore.actions.fetchAllSubgroups),
      exhaustMap((action) =>
        this.service.fetchSubgroups().pipe(
          map((r) => CodeStore.actions.fetchAllSubgroupsSuccess({ result: r })),
          catchError((error) => of(CodeStore.actions.fetchAllSubgroupsError({ error }))),
        ),
      ),
    ),
  );

  createError = this.actions$.pipe(ofType(CodeStore.actions.createError));
  updateSuccess = this.actions$.pipe(ofType(CodeStore.actions.updateSuccess));
  updateError = this.actions$.pipe(ofType(CodeStore.actions.updateError));

  create = createEffect(() =>
    this.actions$.pipe(
      ofType(CodeStore.actions.create),
      exhaustMap((action) =>
        this.service.create(action.items).pipe(
          map((r) =>
            CodeStore.actions.createSuccess({
              result: r,
            }),
          ),
          catchError((error) => of(CodeStore.actions.createError({ error }))),
        ),
      ),
    ),
  );

  createSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(CodeStore.actions.createSuccess),
      map((r) => CodeStore.actions.removeAllSubgroups()),
    ),
  );

  update = createEffect(() =>
    this.actions$.pipe(
      ofType(CodeStore.actions.update),
      exhaustMap((action) =>
        this.service.update(action.items).pipe(
          map((r) =>
            CodeStore.actions.updateSuccess({
              result: r,
            }),
          ),
          catchError((error) => of(CodeStore.actions.updateError({ error }))),
        ),
      ),
    ),
  );

  delete = createEffect(() =>
    this.actions$.pipe(
      ofType(CodeStore.actions.delete),
      exhaustMap((action) =>
        this.service.deleteByID(action.id).pipe(
          map((r) =>
            CodeStore.actions.deleteSuccess({
              result: action.id,
            }),
          ),
          catchError((error) => of(CodeStore.actions.deleteError({ error }))),
        ),
      ),
    ),
  );
}
