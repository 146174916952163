import { createActionGroup, createReducer, createSelector, on, props } from '@ngrx/store';
import { IAppState } from '../index.state';
import { IMenuItem, IMenuMap } from '../../models/menu.model';
import { uniqBy } from 'lodash-es';
export namespace MenuStore {
  //#region STORE
  export interface IState {
    menu: IMenuItem[];
    menuMap: IMenuMap;
    breadCrumb: { link?: string; label: string }[];
  }

  export const initialState: IState = {
    menu: [],
    menuMap: {},
    breadCrumb: [],
  };
  //#endregion

  //#region ACTIONS
  export const action = createActionGroup({
    source: 'Auth API',
    events: {
      setMenu: props<{ result: IMenuItem[] }>(),
      setMenuSuccess: props<{ result: IMenuItem[] }>(),
      changedRoute: props<{ pageTitle: string; path: string }>(),
    },
  });
  //#endregion

  //#region SELECTORS
  export namespace Selectors {
    const select = (state: IAppState) => state.menu;
    export const selectMenus = createSelector(select, (state) => state.menu);
    export const selectMenuMap = createSelector(select, (state) => state.menuMap);
    export const selectBreadCrumb = createSelector(select, (state) => state.breadCrumb);
  }
  //#endregion

  //#region REDUCER
  export const reducer = createReducer(
    initialState,
    on(action.setMenuSuccess, (state, { result }) => ({ ...state, menu: result })),
    on(action.setMenuSuccess, (state, { result }) => ({ ...state, menuMap: buildMenuMap(result) })),
     
    on(action.changedRoute, (state, param) => ({
      ...state,
      breadCrumb: getBreadCrumbWithPageTitle(param.pageTitle, param.path, state.menuMap),
    })),
  );

  function buildMenuMap(menuList: IMenuItem[]) {
    const map: IMenuMap = {};
    for (const { children, ...item } of menuList) {
      if (!item.path) continue;
      map[item.path] = { menu: item, children: {} };
      if (children) map[item.path].children = buildMenuMap(children);
    }
    return map;
  }

  function getBreadCrumb(path = location.pathname, menuMap: IMenuMap) {
    // console.log(path);
    let currentMenuMap = menuMap;
    const menuItems: IMenuItem[] = [];
    for (const chunk of path.split('/')) {
      if (!currentMenuMap[chunk]) continue;
      menuItems.push(currentMenuMap[chunk].menu);
      currentMenuMap = currentMenuMap[chunk].children;
    }
    let joinedPaths = '';
    return menuItems.map((x, i) => {
      joinedPaths += '/' + x.path!;
      return {
        label: x.label!,
        link: joinedPaths || undefined,
      };
    });
  }

  const getBreadCrumbWithPageTitle = (pageTitle: string, path = location.pathname, menuMap: IMenuMap) => {
    // debugger
    return uniqBy(
      getBreadCrumb(path, menuMap).concat(pageTitle ? [{ label: pageTitle, link: undefined }] : []),
      'label',
    );
  };
  //#endregion
}
