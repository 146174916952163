import { Injectable, inject } from '@angular/core';
import { debounceTime, filter, first, forkJoin, from, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { createEffect, ofType } from '@ngrx/effects';
import { BaseEffect } from '../base.effect';
import { AuthStore } from './auth.reducer';
import { StorageService } from '../../services/storage.service';
import { EAuthRole, IAuthResp } from './auth.model';
import { environment } from '../../../environments/environment';
import { IUser } from '../user/user.model';
import { ICandidate } from '../../../../../admin/src/app/dashboard/intermediary-desk/cifm/candidates/candidate.model';
import { CandidateService } from '../../../../../admin/src/app/dashboard/intermediary-desk/cifm/candidates/candidate.service';
import { UsersService } from '../../../../../admin/src/app/dashboard/set-ups/users/users.service';
import { CompaniesService } from '../../../../../admin/src/app/dashboard/set-ups/companies/companies.service';
import { AuthenticationService } from '../../authentication/authentication.service';
import { AgentService } from '../../../../../admin/src/app/dashboard/intermediary-desk/extras/agent.service';
import { ITiedAgent } from '../../../../../admin/src/app/dashboard/intermediary-desk/extras/agent.model';
import { IUserGroup } from '../user-group/user-group.model';
import { DocumentService } from '../../services/document.service';
import { ERefCat } from '../../models/document.model';

@Injectable()
export class AuthEffect extends BaseEffect {
  sS = inject(StorageService);
  candS = inject(CandidateService);
  agentS = inject(AgentService);
  authS = inject(AuthenticationService);
  docS = inject(DocumentService);
  userS = inject<UsersService<IUser>>(UsersService<IUser>);

  protected readonly authLSKey = 'efrmoirirjni';
  protected readonly candidateLSKey = 'efrmoirirjnic';
  protected readonly userLSKey = 'efrmoirirjniu';
  protected readonly userGroupLSKey = 'efrmoirirjniug';
  protected readonly candidateAgentLSKey = 'efrmoirirjnia';
  protected readonly candidatRegDocumentLSKey = 'efrmoirirjnicrd';
  protected compS = inject(CompaniesService);

  saveToLocalAuthObj = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.saveToLocalAuthObj),
      mergeMap((action) => this.sS.saveItem$<IAuthResp>(this.authLSKey, action.response)),
      map(() => AuthStore.actions.saveToLocalAuthObjSuccess()),
    ),
  );

  getFromLocalAuthObj = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.getFromLocalAuthObj),
      mergeMap(() => this.sS.getItem$<IAuthResp>(this.authLSKey)),
      map((response) => AuthStore.actions.setAuthObj({ response })),
    ),
  );

  getFromLocalCandidate = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.getFromLocalCandidate),
      filter((x) => environment.isCandidate),
      mergeMap(() => this.sS.getItem$<ICandidate>(this.candidateLSKey)),
      map((response) => AuthStore.actions.setCandidate({ response })),
    ),
  );

  getFromLocalUserGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.getFromLocalUserGroup),
      filter((x) => !environment.isCandidate),
      mergeMap(() => this.sS.getItem$<IUserGroup>(this.userGroupLSKey)),
      map((response) => AuthStore.actions.setUserGroup({ response })),
    ),
  );

  getFromLocalCandidateAgent = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.getFromLocalCandidateAgent),
      filter((x) => environment.isCandidate),
      mergeMap(() => this.sS.getItem$<ITiedAgent>(this.candidateAgentLSKey)),
      map((response) => AuthStore.actions.setCandidateAgent({ response })),
    ),
  );

  getFromLocalCandidateRegDocuments = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.getFromLocalCandidateRegDocuments),
      filter((x) => environment.isCandidate),
      mergeMap(() => this.sS.getItem$(this.candidatRegDocumentLSKey)),
      map((response) => AuthStore.actions.setCandidateAgent({ response })),
    ),
  );

  getFromLocalUser = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.getFromLocalUser),
      filter((x) => !environment.isCandidate),
      mergeMap(() => this.sS.getItem$<IUser>(this.userLSKey)),
      map((response) => AuthStore.actions.setUser({ response })),
    ),
  );

  getFromOnlineCandidate = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.getFromOnlineCandidate),
      filter((x) => environment.isCandidate),
      mergeMap((action) =>
        action.email
          ? of(action.email)
          : this.store.select(AuthStore.Selectors.authObj).pipe(
              first(),
              map((r) => r?.email),
            ),
      ),
      filter((email) => !!email),
      mergeMap((email) =>
        this.candS
          .search({ personalEmail: email })
          .pipe(map((r) => r.content.find((x) => x.personalEmail?.toLowerCase() == email?.toLowerCase()))),
      ),
      mergeMap((candidate) =>
        (candidate
          ? this.sS.saveItem$<ICandidate>(this.candidateLSKey, candidate)
          : this.sS.removeItem$(this.candidateLSKey)
        ).pipe(map(() => candidate)),
      ),
      tap((response) => {
        if (response) {
          this.store.dispatch(AuthStore.actions.getFromOnlineCandidateAgent());
        }
      }),
      mergeMap((candidate) => {
        return candidate
          ? of(AuthStore.actions.setCandidate({ response: candidate }))
          : from(this.uS.info(`Candidate could not be found`, 0)).pipe(
              map(() => AuthStore.actions.logout({ redirectRoute: 'wpce' })),
            );
      }),
      tap(() => {
        this.store.dispatch(AuthStore.actions.getUserCompany());
      }),
    ),
  );

  getFromOnlineUser = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.getFromOnlineUser),
      filter((x) => !environment.isCandidate),
      switchMap((action) =>
        this.store.select(AuthStore.Selectors.authObj).pipe(
          first(),
          map((r) => r),
        ),
      ),
      filter(
        (authObj) =>
          !!authObj?.email &&
          !authObj.userDetails.authorities.some((x) => x.authority == EAuthRole.SUPER_ADMIN),
      ),
      switchMap((authObj) => this.authS.getUserObject(authObj!, 0)),
      mergeMap((user) =>
        (user ? this.sS.saveItem$<IUser>(this.userLSKey, user) : this.sS.removeItem$(this.userLSKey)).pipe(
          map(() => user),
        ),
      ),
      tap((response) => {
        if (response) {
          this.store.dispatch(AuthStore.actions.getUserCompany());
          // debugger;
          if (environment.isAdmin && response.restrictedAccess) this.uS.router.navigateByUrl('/wpp');
          // else if (environment.isPartner && !response.restrictedAccess)
          //   this.uS.router.navigateByUrl('/wpa');
        }
      }),
      map((response) => {
        // debugger;
        return response ? AuthStore.actions.setUser({ response }) : AuthStore.actions.logout({});
      }),
    ),
  );

  getCandidateAgent = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.getFromOnlineCandidateAgent),
      filter(() => !!environment.isCandidate),
      mergeMap(() =>
        this.store.select(AuthStore.Selectors.candidate).pipe(
          first((x) => !!x),
          map((r) => r?.ria),
        ),
      ),
      filter((ria) => !!ria),
      debounceTime(100),
      switchMap((ria) => this.agentS.getTiedAgentByRIA(ria!)),
      map((response) => AuthStore.actions.getFromOnlineCandidateAgentSuccess({ response: response! })),
    ),
  );

  saveCandidateAgentToLocal = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.getFromOnlineCandidateAgentSuccess),
      mergeMap((action) => this.sS.saveItem$(this.candidateAgentLSKey, action.response)),
      map((response) => AuthStore.actions.savedCandidateAgent()),
    ),
  );
  setCandidateAgentFromLocal = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.getFromOnlineCandidateAgentSuccess),
      map((response) => AuthStore.actions.setCandidateAgent(response)),
    ),
  );

  getCandidateRegDocuments = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.getFromOnlineCandidateRegDocuments),
      filter(() => !!environment.isCandidate),
      mergeMap(() =>
        this.store.select(AuthStore.Selectors.candidate).pipe(
          first((x) => !!x),
          map((r) => r?.candidateNo),
        ),
      ),
      filter((candidateNo) => !!candidateNo),
      debounceTime(100),
      switchMap((candidateNo) =>
        forkJoin(
          this.candS
            .documentsToUploadList()
            .map(({ code, mandatory }) =>
              this.docS
                .search({ refNo: candidateNo, category: code, refCat: ERefCat.candidate })
                .pipe(map(({ content }) => ({ code, mandatory, document: content?.[0] }))),
            ),
        ),
      ),
      map((response) => AuthStore.actions.getFromOnlineCandidateRegDocumentsSuccess({ response })),
    ),
  );

  saveCandidateRegDocumentsToLocal = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.getFromOnlineCandidateRegDocumentsSuccess),
      mergeMap((action) => this.sS.saveItem$(this.candidatRegDocumentLSKey, action.response)),
      map((response) => AuthStore.actions.savedCandidateRegDocuments()),
    ),
  );

  setCandidateRegDocumentsFromLocal = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.getFromOnlineCandidateRegDocumentsSuccess),
      map((response) => AuthStore.actions.setCandidateRegDocuments(response)),
    ),
  );

  getUserCompany = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.getUserCompany),
      mergeMap(() =>
        environment.isCandidate
          ? this.store.select(AuthStore.Selectors.candidate).pipe(
              first((x) => !!x),
              map((r) => r?.company),
            )
          : this.store.select(AuthStore.Selectors.user).pipe(
              first((x) => !!x),
              map((r) => r?.company),
            ),
      ),
      filter((companyCode) => !!companyCode),
      debounceTime(100),
      mergeMap((companyCode) =>
        this.compS
          .fetchAll({ companyCode })
          .pipe(
            map((r) => r.content.find((x) => x.companyCode?.toLowerCase() == companyCode?.toLowerCase())),
          ),
      ),
      map((response) => AuthStore.actions.setUserCompany({ response })),
    ),
  );

  logout = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.logout),
      mergeMap((candidate) =>
        of(this.sS.clear()).pipe(
          tap(() => {
            location.href = '/';
          }),
        ),
      ),
      map(() => AuthStore.actions.logoutSuccess()),
    ),
  );

  setUser = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthStore.actions.setUser),

      tap((map) => {
        environment.userCompanyCode = environment.userCompanyCode || map.response?.company;
        // debugger;
        environment.userCompanyCodeSignal.set(environment.userCompanyCode);
      }),
      map(() => AuthStore.actions.setUserSuccess()),
    ),
  );
}
