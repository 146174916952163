import { Injectable } from '@angular/core';
import { AppRouteService as ARS } from 'ets-fe-ng-sdk';

@Injectable({
  providedIn: 'root',
})
export class AppRouteService extends ARS{
  
}

export enum AppRouteState {
  started = 'STARTED',
  completed = 'COMPLETED',
  cancelled = 'CANCELLED',
  ongoing = 'ONGOING',
}
