import { IMenuItem } from "../../models/menu.model";

export const setupMenu: IMenuItem = {
    label: `Set Ups`,
    path: `set-ups`,
    icon: 'fa fa-cogs',
    children: [
      {
        path: `parameters`,
        label: `Parameters`,
      },
      // {
      //   path: 'correspondence',
      //   label: 'Correspondence',
      //   children: [
      //     {
      //       label: 'Set Up',
      //       path: 'set-up',
      //     },
      //     {
      //       label: 'Template',
      //       path: 'template',
      //     },
      //     {
      //       label: 'Template Mapping',
      //       path: 'template-mapping',
      //     },
      //   ],
      // },
      { path: `companies`, label: `Companies` },
      { path: `tasks`, label: `Tasks` },
      { path: `agent-types`, label: `Agent Types` },
      { path: `users`, label: `Users` },
      { path: `user-groups`, label: `User Groups` },
      { path: `configuration`, label: `Configuration` },
    ],
  }