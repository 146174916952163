import { Routes } from '@angular/router';
import DashboardRoutes from './dashboard/dashboard.routes';
import { DashboardLayoutComponent } from '../../../shared/src/lib/components/layouts/dashboard-layout/dashboard-layout.component';

export const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    children: [ 
      {
        path: '', 
        loadChildren: () => import('./dashboard/dashboard.routes'),
      }, 
    ],
  },
];
