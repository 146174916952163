import { isDevMode } from '@angular/core';
import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createReducer,
  createSelector,
  MetaReducer,
  on,
} from '@ngrx/store';
import { IAppState } from './index.state';
import { AuthStore } from './auth/auth.reducer';
import { CompanyStore } from './company/company.reducer';
import { ConfigurationStore } from './configuration/configuration.reducer';
import { UserStore } from './user/user.reducer';
import { UserGroupStore } from './user-group/user-group.reducer';
import { AgentTypeStore } from './agent-type/agent-type.reducer';
import { CodeStore } from './code/code.reducer';
import { MenuStore } from './menu/menu.reducer';
import { CourseStore } from './course/course.reducer';

export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    console.log(action.type, { action, state });
    return reducer(state, action);
  };
}
export const metaReducers: MetaReducer[] = isDevMode() ? [debug] : [];

export const reducers: ActionReducerMap<IAppState> = {
  agentType: AgentTypeStore.reducer,
  auth: AuthStore.reducer,
  code: CodeStore.reducer,
  course: CourseStore.reducer,
  // codeGroup: CodeGroupStore.reducer,
  company: CompanyStore.reducer,
  configuration: ConfigurationStore.reducer,
  menu: MenuStore.reducer,
  user: UserStore.reducer,
  userGroup: UserGroupStore.reducer,
};
