import { Injectable, inject } from '@angular/core';
import { BaseFacadeService } from './base.facade.service';
import { IMenuItem } from '../models/menu.model';
import { MenuStore } from '../@ngrx/menu/menu.reducer';
import { MenuEffect } from '../@ngrx/menu/menu.effect';

@Injectable({
  providedIn: 'root',
})
export class MenuFacadeService extends BaseFacadeService {
  protected effect = inject(MenuEffect);

  menuIndex: { [path: string]: IMenuItem } = {};

  setMenu(menu: IMenuItem[]) {
    this.store.dispatch(MenuStore.action.setMenu({ result: menu }));
  }

  changedRoute(pageTitle: string) {
    // debugger
    this.store.dispatch(MenuStore.action.changedRoute({ pageTitle, path: location.pathname }));
  }

  selectMenus() {
    return this.store.select(MenuStore.Selectors.selectMenus);
  }

  selectBreadcrumb() {
    return this.store.select(MenuStore.Selectors.selectBreadCrumb);
  }

  selectMenuMap() {
    return this.store.select(MenuStore.Selectors.selectMenuMap);
  }
}
