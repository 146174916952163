import { ApplicationConfig } from '@angular/core';

import { routes } from './app.routes';
import { sharedAppConfig } from '../../../shared/src/lib/shared-app.config';
import { environment } from '../../../shared/src/environments/environment';
import { ESystem } from '../../../shared/src/lib/models/index.model';
// import { authReducer } from './@ngrx/reducers/auth.reducer';

function setVariables() {
  environment.appName = 'Admin Portal';
  // environment.allowRegistration = false;
  environment.system = ESystem.admin;
  environment.sentryKey =
    'https://92c694630cb97e9aa95d2fd63bf4e3da@o4507594374250496.ingest.de.sentry.io/4507596104597584';
  return environment;
}
export const appConfig: ApplicationConfig = sharedAppConfig(routes, setVariables());

