<div class="example-container" [class.is-mobile]="uS.isMobileSignal()">
  <div [hidden]="lS.isFullscreen()" class="example-toolbar" #topbar>
    <div class="container">
      <mat-toolbar color="primary" class="px-0 justify-content-between">
        <div class="d-flex align-items-center">
          @if (!uS.isDesktopSignal()) {
            <app-btn
              mclass="text-light me-16"
              (mclick)="mobileMenu.open()"
              type="clear"
              [mini]="true"
              customIcon="fa fa-bars" />
          }
          <img src="/assets/images/logo.png" class="logo" alt="" />
          <h1 class="example-app-name">{{ uS.config.appName }}</h1>
        </div>
        @if (uS.isDesktopSignal()) {
          <ng-container *ngTemplateOutlet="hMenuList" />
        }

        <div>
          <div class="row row-cols-auto align-items-center">
            @if (!uS.isMobileSignal()) {
              <div class="">
                {{ env().appName }}
              </div>
            }
            <div
              class="d-flex align-items-center pointer"
              [matMenuTriggerFor]="accountMenu"
              [matTooltip]="profileDesc()">
              <mat-icon>account_circle</mat-icon>
            </div>
            <mat-menu #accountMenu="matMenu" class="">
              <button mat-menu-item routerLink="/profile">Profile</button>
              <button mat-menu-item routerLink="/help/view">Help</button>
              <button mat-menu-item (click)="authS.logout()">Log Out</button>
            </mat-menu>
          </div>
        </div>
      </mat-toolbar>

      @if (uS.isTabletSignal()) {
        <div class="d-flex justify-content-center">
          <ng-container *ngTemplateOutlet="hMenuList" />
        </div>
      }
      <ng-template #hMenuList>
        <div class="">
          <div class="hMenu">
            @for (item of menu(); track item.label) {
              @if (item.children?.length) {
                <a class="hMenuItem" [matMenuTriggerFor]="menu" routerLinkActive="selected">
                  <i class="{{ item.icon }} " [ngClass]="{ padIcon: item.icon }"></i>
                  {{ item.label }}
                  <i class="fa fa-chevron-down"></i>
                </a>
                <mat-menu #menu="matMenu" class="menuModal">
                  @for (subItem of item.children; track subItem.label) {
                    <a mat-menu-item routerLinkActive="selected" routerLink="{{ subItem.fullPath }}">
                      {{ subItem.label }}
                    </a>
                  }
                </mat-menu>
              } @else {
                <a class="hMenuItem" routerLinkActive="selected" routerLink="{{ item.fullPath }}">
                  <i class="{{ item.icon }} " [ngClass]="{ padIcon: item.icon }"></i>
                  {{ item.label }}
                </a>
              }
            }
          </div>
        </div>
      </ng-template>
      <modal-comp header="Menu" #mobileMenu [showFooter]="false" width="90%" height="100vh">
        <ng-template modalBody>
          <mat-nav-list>
            @for (item of menu(); track item.label) {
              @if (item.children?.length) {
                <a
                  class=""
                  mat-list-item
                  routerLinkActive="selected"
                  #ra="routerLinkActive"
                  (click)="mobileMenu.close()"
                  [activated]="ra.isActive">
                  <i class="{{ item.icon }} " [ngClass]="{ padIcon: item.icon }"></i>
                  {{ item.label }}
                </a>
                @for (subItem of item.children; track subItem.id) {
                  <a
                    mat-list-item
                    class="ps-16"
                    routerLinkActive="selected"
                    routerLink="{{ subItem.fullPath }}"
                    #ra="routerLinkActive"
                    (click)="mobileMenu.close()"
                    [activated]="ra.isActive">
                    -
                    <span class="ps-8">{{ subItem.label }}</span>
                  </a>
                }
              } @else {
                <a
                  mat-list-item
                  routerLink="{{ item.fullPath }}"
                  routerLinkActive="selected"
                  #ra="routerLinkActive"
                  (click)="mobileMenu.close()"
                  [activated]="ra.isActive">
                  <i class="{{ item.icon }} " [ngClass]="{ padIcon: item.icon }"></i>
                  {{ item.label }}
                </a>
              }
            }
          </mat-nav-list>
        </ng-template>
      </modal-comp>
    </div>
  </div>

  <div
    [hidden]="lS.isFullscreen()"
    [ngClass]="{ container: !lS.isWide() }"
    [style.marginTop.px]="topbar.offsetHeight">
    <div class="bodyHead row row-cols-auto gx-2 m-0 align-items-center" #bh>
      @if (!isHome()) {
        <a (click)="uS.back()" class="text-light d-flex align-items-center pointer">
          <i class="material-icons">arrow_back</i>
        </a>
        <span>|</span>
        <a routerLink="/" class="text-light d-flex align-items-center"><i class="material-icons">home</i></a>
        <span>/</span>
      }
      @if (!uS.isMobileSignal()) {
        @for (mi of breadCrumb(); track mi.link) {
          @if ($last) {
            <span>{{ mi.label }}</span>
          } @else {
            <a routerLink="{{ mi.link }}">{{ mi.label }}</a>
            <span>/</span>
          }
        }
      }
    </div>
  </div>
  <div class="body" [style.height]="'calc(100% - ' + bh.offsetHeight + 'px)'">
    <div
      class="card"
      [ngClass]="{ container: !lS.isFullscreen(), fullScreen: lS.isFullscreen() }"
      #bodyCont
      id="{{ id }}"
      PSBox>
      <router-outlet />
      <div class="pb-2"></div>
    </div>
  </div>
  <!-- @else {
    <div #bodyCont class="fullscreenCont" id="{{ id }}">
      <router-outlet />
    </div>
  } -->
</div>
