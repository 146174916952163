import { IMenuItem } from '../../models/menu.model';

export const candidateMenu: IMenuItem[] = [
  {
    path: `home`,
    label: `Home`,
    icon: 'fa fa-home',
  },
  {
    path: `courses`,
    label: `Courses`,
    icon: 'fa fa-book-reader',
  },
  {
    path: `exam`,
    label: `Examination`,
    icon: 'fa fa-book',
    children: [
      // { path: `find-certificate`, label: `Find Certificate` },
      {
        path: `take`,
        label: `Take Examinations`,
      },
      { path: `previous`, label: `Examination History` },
      // { path: `exam-bank`, label: `Exam Bank` },
      // { path: `take-exam`, label: `Take Exam` },
    ],
  },
];
